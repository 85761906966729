<template>
	<div class="cbox">
		<div v-if="false" class="headbar">

			<div style="padding-top: 14px;position:relative">

				<div style=" width: 200px;  margin-top:-10px;">
					<div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
						<img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
					</div>
					<div style="margin-left: 50px; color:#535353; font-size:20px !important">就业名册</div>
				</div>

				<!-- <div style="position: absolute; right: 0; top:0">
					<el-button type="infor" @click="donwAll" size="small" icon="el-icon-download">下载全部文件</el-button>
				</div> -->

			</div>
		</div>
		<div class="w_100 h_100 bcontent flexStart borderbox" style="padding:20px 0;">
			<div class="h_100"
				style="flex-shrink:0;width:250px; overflow:auto; background-color:#E8EDF8;border-radius:4px;">
				<div class="w_100" style="background-color:#E8EDF8;border-radius:4px;">
					<div :class="['school', schoolId == 'all' ? 'ckd' : '']" @click="setSchool('all')">全部学校</div>
					<div :class="['school', schoolId == item.id ? 'ckd' : '']" @click="setSchool(item.id)"
						v-for="(item, idx) in SchoolList" :key="idx">{{ item.platform }}<i v-if="!item.hasData"
							class="el-icon-star-on" style="color:orangered"></i></div>
				</div>

			</div>

			<div class="flex_1 h_100 ml20 flexCloumn" style="overflow:hidden;">
				<!-- <el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="毕业生就业名册" name="first"></el-tab-pane>


				</el-tabs> -->

				<jiuyetongji class="w_100 h_100 flexCloumn" :tpmid="schoolId" v-if="schoolId"></jiuyetongji>

			</div>

		</div>

	</div>
</template>

<script>

import jiuyetongji from './com/jiuyetongji.vue'

export default {
	components: {
		jiuyetongji


	},
	data() {
		return {
			dialogShow: false,
			dialogshow1: false,
			activeName: "first",
			schoolId: 'all',
			tjData: null,
			SchoolList: []
		}
	},
	mounted() {
		this.getSchoolList()
		//this.getDataList()

	},
	methods: {
		getSchoolList() {
			this.$http.post("/api/school_list").then(res => {
				let SchoolList = res.data
				this.$http.post("/api/get_jiuye_tjschools").then(res2 => {
					for (let item of res2.data) {
						for (let s of SchoolList) {
							if (s.id == item.pmid) {
								s.hasData = true
							}
						}
					}
					this.SchoolList = SchoolList
					for (let item of SchoolList) {
						if (item.hasData) {
							this.setSchool(item.id)
							break
						}
					}
				})
			})
		},
		setSchool(id) {
			this.schoolId = 0;
			this.tjData = null;
			setTimeout(() => {
				this.schoolId = id
				this.getDataList()
			}, 200)

		},
		handleClick() {
			if (this.activeName == "second") {
				this.getDataList()

			}
		},
		getDataList() {
			return
			this.$http.post("/api/get_jiuye_tjtable", { tpmid: this.schoolId }).then(res => {
				if (!res.data) {
					this.$message.error("还没有上传统计表")
				} else {
					this.tjData = res.data
					this.$nextTick(e => {
						$("table", "#tableContent").addClass("dttable")
					})
				}



			})


		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		viewInfo(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name
			this.xunfang = e

			this.dialogShow = true
		},
		uploadFiles(e) {
			if (e.src.indexOf("doc") > 0) {

				this.$http.post("/api/import_jiuyetj_word", {
					url: e.src
				}).then(res => {


					this.$message.success("导入成功")
					if (res.data.msg && res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" + msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					}
					this.dialogshow1 = false
					this.activeName = "second"
					this.getDataList()
					setTimeout(() => {
						this.handleClick()
					}, 500);



				})

			} else {
				this.$http.post("/api/import_jiuyefl_xls", {
					url: e.src
				}).then(res => {

					this.getDataList()
					this.$message.success("导入成功")
					if (res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" + msg['巡访学生'] + ":" + msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					}
					this.dialogShow = false
					this.activeName = "none"
					this.$nextTick(e => {
						this.activeName = "third"
					})


				})
			}
		},
		donwAll() {
			this.$http.post("/api/all_files_down", {
				school_id: this.schoolId
			}).then(res => {
				if (res.data.file) {
					
					window.open(res.data.file, "_blank")
				} else {
					this.$message({
						type: 'error',
						message: '该学校未上传统计表!'
					});
				}
			})

		}

	}
}
</script>

<style scoped>
.school {
	line-height: 30px;
	border-radius: 4px;
	cursor: pointer;
	padding: 5px 10px;
}

.school:hover {
	background-color: aliceblue;

}

.ckd {
	background-color: #547df2;
	color: #fff;
}

.ckd:hover {
	background-color: #547df2;
	color: #fff;
}
</style>